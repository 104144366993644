import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import pic04 from '../assets/images/pic04.jpg'

class Generic extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title="TBT - Map" />
        <HeaderGeneric />
        <div id="main">
          <section id="content" className="main">
            <span className="image main">
              <img src={pic04} alt="" />
            </span>
            <h2>Map</h2>
            <p>
              <Link to="/" className="button">
                about this project...
              </Link>
            </p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
