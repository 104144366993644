import React from 'react'
import { Link } from 'gatsby'

const HeaderGeneric = props => (
  <header id="header" className="header-2nd">
    <h1>Map</h1>
    <p>&nbsp;</p>
  </header>
)

export default HeaderGeneric
